'use client';

import { useCallback, useLayoutEffect } from 'react';

import { View } from '@/components/Primitives';
import { useCanBodyScrollAtom } from '@/js/atoms';
import { cn } from '@/js/helper';

import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
	className?: string;
	close: () => void;
	closeOnOverlayClick?: boolean;
	isOpen: boolean;
	overlayClassName?: string;
};

const Modal = ({ children, className, close, closeOnOverlayClick, isOpen, overlayClassName }: Props) => {
	const [, setCanBodyScroll] = useCanBodyScrollAtom();

	useLayoutEffect(() => {
		setCanBodyScroll(!isOpen);

		return () => {
			setCanBodyScroll(true);
		};
	}, [setCanBodyScroll, isOpen]);

	const handleOverlayClick = useCallback(() => {
		if (closeOnOverlayClick) {
			close();
		}
	}, [close, closeOnOverlayClick]);

	if (!isOpen) {
		return null;
	}

	return (
		<View
			aria-modal
			className={cn('fixed inset-0 z-50 overflow-y-auto bg-primary-900 bg-opacity-60 p-[4vw] md:p-8', overlayClassName)}
			onClick={handleOverlayClick}
		>
			<View
				className={cn('m-auto rounded-2xl bg-white p-8', className)}
				onClick={(event) => {
					event.stopPropagation();
				}}
			>
				{children}
			</View>
		</View>
	);
};

export { Modal };
